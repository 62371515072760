import { useEffect, useState } from "react";
import { format } from "date-fns";
import Plot from "react-plotly.js";
import ReactModal from "react-modal";
import IrrigationIndicatorSettingModal from "./IrrigationIndicatorSettingModal";
import useLatestSP2DBInfo from "../../hooks/use-latest-sp2-db-info";
import useHourlyTranspirationPerDay from "../../hooks/use-hourly-transpiration-per-day";
import useHourlyIrrigationWaterAmountsPerDay from "../../hooks/use-hourly-irrigation-water-amounts-per-day";
import useHourlySolarIrradiancePerDay from "../../hooks/use-hourly-solar-irradiance-per-day";

import "./IrrigationIndicator.css";

ReactModal.setAppElement('#root');

const getModalStyle = () => {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    return {
      content: {
        width: "auto",
        height: "fit-content",
        margin: 0
      }
    };
  }
  return {
    content: {
      width: "50%",
      height: "fit-content",
      margin: "auto"
    }
  };
};

export default function IrrigationIndicator({
  houseName,
  houseDisplayName,
  cropName,
  plantingDate,
  arableLandArea,
  idealIrrigationCoefficient,
  plantingDensity,
  solarIrradianceProportionalWaterAmount }) {

  const { timestamp: latestTimestamp } = useLatestSP2DBInfo("transpiration_rate", houseName, plantingDate);
  const { data: transpirationData } = useHourlyTranspirationPerDay(houseName, latestTimestamp);
  const { data: irrigationData } = useHourlyIrrigationWaterAmountsPerDay(houseName, latestTimestamp);
  const { data: solarIrradianceData } = useHourlySolarIrradiancePerDay(houseName, latestTimestamp);

  const [plotData, setPlotData] = useState([]);
  const [plotLayout, setPlotLayout] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const accumulate = (array) => array.reduce((accumulator, currentValue) => {
    const last = accumulator.slice(-1)[0];
    if (typeof last === "number") {
      accumulator.push(currentValue + last);
    } else {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

  const fillDataHourly = array => {
    if (!array || array.length === 0) return [];
    const result = [];
    const lastHour = new Date(array[array.length - 1].timestamp).getHours();
    for (let hour = 0; hour <= lastHour; hour++) {
      const targetData = array.find(data => hour === new Date(data.timestamp).getHours());
      result[hour] = targetData ? targetData.value : 0;
    }
    return result;
  };

  const per10a = (array, arableLandArea) => {
    if (0 < arableLandArea) {
      return array.map(value => value / arableLandArea * 10);
    }
    return [];
  };

  /*
   * 日射量の1時間の平均値(W/m2 = J/m2/s) × 1時間の秒数(s) = 1時間の積算日射量(J/m2)
   * 1時間の積算日射量(J/m2) / 1000000 = 1時間の積算日射量(MJ/m2)
  */
  const accumulateSolarIrradiancePerHour = solarHourlyMeanData => {
    if (!solarHourlyMeanData || solarHourlyMeanData.length === 0) return [];
    return solarHourlyMeanData.map(value => {
      if (typeof value !== "number") return 0;
      const secondPerHour = 60 * 60;
      return value * secondPerHour / 1000000;
    });
  };

  const convertToSolarIrradianceProportionalWaterAmountPer10a = (
    accumulatedSolarIrradianceData,
    plantingDensity,
    solarIrradianceProportionalWaterAmount
  ) => {
    if (!accumulatedSolarIrradianceData || accumulatedSolarIrradianceData.length === 0) return [];
    if (!plantingDensity || typeof plantingDensity !== 'number') return [];
    if (!solarIrradianceProportionalWaterAmount || typeof solarIrradianceProportionalWaterAmount !== 'number') return [];
    return accumulatedSolarIrradianceData.map(value => {
      return value * plantingDensity * solarIrradianceProportionalWaterAmount;
    });
  };

  useEffect(() => {

    if (!transpirationData || !irrigationData) return;

    const transpirationPlotData = fillDataHourly(transpirationData);
    const irrigationPlotData = per10a(fillDataHourly(irrigationData), arableLandArea);
    const irrigationIndicatorPlotData = transpirationPlotData.map(value => value * (0 <= idealIrrigationCoefficient ? idealIrrigationCoefficient : 1.5));
    const solarIrradianceProportionalIrrigationPlotData = convertToSolarIrradianceProportionalWaterAmountPer10a(
      accumulateSolarIrradiancePerHour(fillDataHourly(solarIrradianceData)),
      plantingDensity,
      solarIrradianceProportionalWaterAmount);
    const accumulatedTranspirationPlotData = accumulate(transpirationPlotData);
    const accumulatedIrrigationPlotData = accumulate(irrigationPlotData);
    const accumulatedIrrigationIndicatorPlotData = accumulatedTranspirationPlotData.map(value => value * (0 <= idealIrrigationCoefficient ? idealIrrigationCoefficient : 1.5));
    const accumulatedSolarIrradianceProportionalIrrigationPlotData = accumulate(solarIrradianceProportionalIrrigationPlotData);

    const transpirationBarChartData = {
      name: "蒸散量",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: transpirationPlotData,
      type: "bar",
      marker: {
        color: "#A9CCAB"
      },
      hovertemplate: "%{y} t/10a",
      yaxis: "y"
    };

    const irrigationBarChartData = {
      name: "かん水実績",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: irrigationPlotData,
      type: "bar",
      marker: {
        color: "#A3BCE2"
      },
      hovertemplate: "%{y} t/10a",
      yaxis: "y"
    };

    const irrigationIndicatorBarChartData = {
      name: "標準かん水量",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: irrigationIndicatorPlotData,
      type: "bar",
      marker: {
        color: "#81CDDF"
      },
      hovertemplate: "%{y} t/10a",
      yaxis: "y"
    };

    const solarIrradianceProportionalIrrigationBarChartData = {
      name: "日射比例かん水量",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: solarIrradianceProportionalIrrigationPlotData,
      type: "bar",
      marker: {
        color: "#FF6066"
      },
      hovertemplate: "%{y} t/10a",
      yaxis: "y"
    };

    const transpirationLineChartData = {
      name: "蒸散量 (積算)",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: accumulatedTranspirationPlotData,
      mode: "lines+markers",
      line: {
        shape: "spline",
        color: "#009944"
      },
      type: "scatter",
      hovertemplate: "%{y} t/10a",
      yaxis: "y2"
    };

    const irrigationLineChartData = {
      name: "かん水実績 (積算)",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: accumulatedIrrigationPlotData,
      mode: "lines+markers",
      line: {
        shape: "spline",
        color: "#0068B7"
      },
      type: "scatter",
      hovertemplate: "%{y} t/10a",
      yaxis: "y3"
    };

    const irrigationIndicatorLineChartData = {
      name: "標準かん水量 (積算)",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: accumulatedIrrigationIndicatorPlotData,
      mode: "lines+markers",
      line: {
        shape: "spline",
        color: "#008FD5"
      },
      type: "scatter",
      hovertemplate: "%{y} t/10a",
      yaxis: "y4"
    }

    const solarIrradianceProportionalIrrigationLineChartData = {
      name: "日射比例かん水量 (積算)",
      x: [ "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時", "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時" ],
      y: accumulatedSolarIrradianceProportionalIrrigationPlotData,
      mode: "lines+markers",
      line: {
        shape: "spline",
        color: "#FF1515"
      },
      type: "scatter",
      hovertemplate: "%{y} t/10a",
      yaxis: "y5"
    }

    setPlotData([
      transpirationBarChartData,
      irrigationBarChartData ,
      irrigationIndicatorBarChartData,
      solarIrradianceProportionalIrrigationBarChartData,
      transpirationLineChartData,
      irrigationLineChartData,
      irrigationIndicatorLineChartData,
      solarIrradianceProportionalIrrigationLineChartData
    ]);

    const yaxisRangeOfBarChar = [
      Math.min(
        ...irrigationPlotData,
        ...irrigationIndicatorPlotData,
        ...solarIrradianceProportionalIrrigationPlotData
      ),
      Math.max(
        ...irrigationPlotData,
        ...irrigationIndicatorPlotData,
        ...solarIrradianceProportionalIrrigationPlotData
      )
    ];
    const yaxisRangeOfLineChart = [
      Math.min(
        ...accumulatedIrrigationPlotData,
        ...accumulatedIrrigationIndicatorPlotData,
        ...accumulatedSolarIrradianceProportionalIrrigationPlotData
      ),
      Math.max(
        ...accumulatedIrrigationPlotData,
        ...accumulatedIrrigationIndicatorPlotData,
        ...accumulatedSolarIrradianceProportionalIrrigationPlotData
      )
    ];

    setPlotLayout({
      barmode: "group",
      bargroupgap: 0.2,
      autosize: true,
      hovermode: "x unified",
      dragmode: false,
      legend: {
        orientation: "h" ,
        x: 0.5,
        y: 1.2,
        xanchor: "center",
        yanchor: "middle"
      },
      yaxis: {
        title: "1時間単位量:棒表示 t/10a",
        range: yaxisRangeOfBarChar,
        fixedrange: true,
      },
      yaxis2: {
        title: "日積算量:線表示 t/10a",
        overlaying: 'y',
        range: yaxisRangeOfLineChart,
        fixedrange: true,
        side: "right"
      },
      yaxis3: {
        overlaying: 'y',
        range: yaxisRangeOfLineChart,
        fixedrange: true,
        side: "right"
      },
      yaxis4: {
        overlaying: 'y',
        range: yaxisRangeOfLineChart,
        fixedrange: true,
        side: "right"
      },
      yaxis5: {
        overlaying: 'y',
        range: yaxisRangeOfLineChart,
        fixedrange: true,
        side: "right"
      },
    });

    return () => setPlotData([]);

  }, [arableLandArea, transpirationData, irrigationData, solarIrradianceData, idealIrrigationCoefficient, plantingDensity, solarIrradianceProportionalWaterAmount]);

  const plotConfig = {
    displayModeBar: false
  };

  return (
    <div className="irrigation-indicator-container">
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        style={getModalStyle()}
      >
        <IrrigationIndicatorSettingModal
          houseName={houseName}
          houseDisplayName={houseDisplayName}
          cropName={cropName}
          idealIrrigationCoefficient={idealIrrigationCoefficient}
          plantingDensity={plantingDensity}
          solarIrradianceProportionalWaterAmount={solarIrradianceProportionalWaterAmount}
          onClose={handleCloseModal}
        />
      </ReactModal>
      <div className="irrigation-indicator-title">
        <div className="irrigation-indicator-title-text">かん水指標</div>
        { latestTimestamp && <span className="irrigation-indicator-timestamp">{`${format(latestTimestamp, "yyyy/MM/dd HH:mm")} 時点`}</span> }
      </div>
      <div className="irrigation-indicator-setting-button">
        <button onClick={handleOpenModal}>かん水指標の設定</button>
      </div>
      <div className="irrigation-indicator-content">
        <Plot
          data={plotData}
          layout={plotLayout}
          config={plotConfig}
          style={{ width: "100%", height: "100%" }}
          useResizeHandler={true}
        />
      </div>
    </div>
  );
}
