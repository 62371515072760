import ImageAnalysisPastPlot from "./ImageAnalysisPastPlot";
import ImageAnalysisPastImageSlider from "./ImageAnalysisPastImageSlider";

import "./ImageAnalysisPast.css";

export default function ImageAnalysisPast({ houseName }) {
    return (
        <div className="image-analysis-past-container">
            <ImageAnalysisPastPlot houseName={houseName} />
            <ImageAnalysisPastImageSlider houseName={houseName} />
        </div>
    );
};
