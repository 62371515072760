import { useState, useEffect } from "react";
import { subDays, startOfDay, endOfDay } from "date-fns";
import Plot from "react-plotly.js";
import client from "../../api/client";
import { useCanopy as _useCanopy } from "../../utils/displaySettingUtil";
import { displayValue } from "../../utils/displayUtil";

const getPlotData = async (houseName, latestTimestamp, displaySetting) => {
  const startDate = startOfDay(subDays(latestTimestamp, 3));
  const endDate = endOfDay(subDays(latestTimestamp, 1));
  const baseParams = {
    house_name: houseName,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    select: "mean",
    sampling_step: "H",
    sampling_interval: 1,
  };
  const envResponse = await client.get("/env", {
    params: {
      ...baseParams,
      targets: [
        "solar_irradiance",
        "co2_concentration",
      ]
    }
  });

  const photoUrl =
    _useCanopy(displaySetting)
      ? "/sp2/db/canopy_photosynthetic_rate"
      : "/sp2/db/photosynthetic_rate";

  const sp2Response = await client.get(photoUrl, {
    params: baseParams
  });

  const solarIrradiance = envResponse.data["solar_irradiance"].reduce((result, { timestamp, value }) => {
    result[0].push(new Date(timestamp));
    result[1].push(value);
    return result;
  }, [[], []]);

  const co2Concentration = envResponse.data["co2_concentration"].reduce((result, { timestamp, value }) => {
    result[0].push(new Date(timestamp));
    result[1].push(value);
    return result;
  }, [[], []]);

  const photosyntheticRate = sp2Response.data.reduce((result, { timestamp, value }) => {
    result[0].push(new Date(timestamp));
    result[1].push(value);
    return result;
  }, [[], []]);

  return { solarIrradiance, co2Concentration, photosyntheticRate };
};

export default function DailyReportDailyPlot({ houseName, latestTimestamp, displaySetting }) {

  const [plotData, setPlotData] = useState([]);

  useEffect(() => {

    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    if (!displaySetting) return;

    getPlotData(houseName, latestTimestamp, displaySetting)
      .then(data => {
        const solarIrradiancePlotData = {
          type: "bar",
          name: "日射量",
          x: data.solarIrradiance[0],
          y: data.solarIrradiance[1].map(value => displayValue(value)),
          yaxis: "y",
          hovertemplate: "%{y} W/m2"
        };
        const co2ConcentrationPlotData = {
          type: "scatter",
          mode: "lines+markers",
          name: "CO2濃度",
          x: data.co2Concentration[0],
          y: data.co2Concentration[1].map(value => displayValue(value)),
          yaxis: "y2",
          hovertemplate: "%{y} ppm"
        };
        const photosyntheticRatePlotData = {
          type: "scatter",
          mode: "lines+markers",
          name: "光合成速度",
          x: data.photosyntheticRate[0],
          y: data.photosyntheticRate[1].map(value => displayValue(value)),
          yaxis: "y3",
          hovertemplate: "%{y} µmol/m2/s"
        };

        setPlotData([
          solarIrradiancePlotData,
          co2ConcentrationPlotData,
          photosyntheticRatePlotData,
        ]);
      });

  }, [houseName, latestTimestamp, displaySetting]);

  const plotLayout = {
    autoresize: true,
    margin: {
      r: 50,
    },
    title: {
      text: "過去3日間",
      automargin: true,
      pad: {
        t: 20
      }
    },
    legend: {
      orientation: "h",
      x: 0,
      y: 1.0,
      xanchor: 'left',
      yanchor: 'bottom'
    },
    hovermode: "x unified",
    dragmode: false,
    yaxis: {
      title: "日射量 (W/m2)",
      range: [0, 1000],
      fixedrange: true,
    },
    yaxis2: {
      overlaying: 'y',
      title: "CO2濃度 (ppm)",
      range: [0, 1000],
      fixedrange: true,
      side: "left",
      anchor: "free",
      autoshift: false,
      shift: -30,
      showticklabels: false,
    },
    yaxis3: {
      overlaying: 'y',
      title: "光合成速度 (µmol/m2/s)",
      range: [-5, 25],
      fixedrange: true,
      side: "right",
      showgrid: false,
      zeroline: false
    },
  };

  const plotConfig = {
    displayModeBar: false,
  };

  return (
    <Plot
      data={plotData}
      layout={plotLayout}
      config={plotConfig}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
    />
  );
};
