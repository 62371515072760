import useLatestSP2Info from "../../hooks/use-latest-sp2-info";
import { useCanopy as _useCanopy } from "../../utils/displaySettingUtil";

import "./SP2InfoListItem.css";
import icon_fresh_weight from "../../assets/sp2_info/sp2_info_icon_fresh_weight_2.png";
import icon_fruit_load from "../../assets/sp2_info/sp2_info_icon_fruit_load_2.png";
import icon_lai from "../../assets/sp2_info/sp2_info_icon_lai_2.png";
import icon_leaf_length from "../../assets/sp2_info/sp2_info_icon_leaf_length_2.png";
import icon_leaf_temperature from "../../assets/sp2_info/sp2_info_icon_leaf_temperature_2.png";
import icon_number_of_blooms from "../../assets/sp2_info/sp2_info_icon_number_of_blooms_2.png";
import icon_photosynthetic_rate from "../../assets/sp2_info/sp2_info_icon_photosynthetic_rate_2.png";
import icon_transpiration_rate from "../../assets/sp2_info/sp2_info_icon_transpiration_rate_2.png";
import { Meter } from "grommet";

const STYLES = {
  fresh_weight: {
    displayName: "新鮮重",
    unit: "g/m2",
    icon: icon_fresh_weight,
    color: "#01A99D",
    meter: {
      min: 0,
      max: 2500,
    },
    digits: 0,
  },
  fruit_load: {
    displayName: "着果負担",
    unit: "個/m2",
    icon: icon_fruit_load,
    color: "#93278F",
    meter: {
      min: 0,
      max: 15,
    },
    digits: 1,
  },
  lai: {
    displayName: "LAI",
    unit: "m2/m2" ,
    icon: icon_lai,
    color: "#39B54A",
    meter: {
      min: 0,
      max: 6,
    },
    digits: 1,
  },
  leaf_length: {
    displayName: "草丈",
    unit: "cm",
    icon: icon_leaf_length,
    color: "#FF7BAC",
    meter: {
      min: 0,
      max: 50,
    },
    digits: 1,
  },
  leaf_temperature: {
    displayName: "葉温",
    unit: "℃",
    icon: icon_leaf_temperature,
    color: "#D4686C",
    meter: {
      min: 0,
      max: 50,
    },
    digits: 1,
  },
  number_of_blooms: {
    displayName: "開花数",
    unit: "個/m2",
    icon: icon_number_of_blooms,
    color: "#FF7BAC",
    meter: {
      min: 0,
      max: 3,
    },
    digits: 1,
  },
  photosynthetic_rate: {
    displayName: "光合成速度",
    unit: "µmol/m2/s",
    icon: icon_photosynthetic_rate,
    color: "#FF1D25",
    meter: {
      min: -5,
      max: 40,
    },
    digits: 1,
  },
  transpiration_rate: {
    displayName: "蒸散速度",
    unit: "mmol/m2/s",
    icon: icon_transpiration_rate,
    color: "#3FA9F5",
    meter: {
      min: 0,
      max: 5,
    },
    digits: 1,
  },
};

const isCanopy = (displaySetting, infoName) => {
  if (infoName === "photosynthetic_rate" ||  infoName === "transpiration_rate") {
    return _useCanopy(displaySetting);
  }
  return false;
};

export default function SP2InfoListItem({ infoName, groupName, houseName, cameraName, cropName, plantingDate, displaySetting }) {

  const { value } = useLatestSP2Info(infoName, groupName, houseName, cameraName, cropName, plantingDate, isCanopy(displaySetting, infoName));
  const meterValue = value - STYLES[infoName].meter.min;
  const meterMax = STYLES[infoName].meter.max - STYLES[infoName].meter.min;

  const displayValue = (value === null) ? "N/A" : Number.parseFloat(value).toFixed(STYLES[infoName].digits);

  return (
    <li className="sp2-info-list-item-container">
      <div className="sp2-info-list-item-icon">
        <img src={STYLES[infoName].icon} alt={STYLES[infoName].displayName} width="100%" />
      </div>
      <div className="sp2-info-list-item-content">
        <div className="sp2-info-list-item-name">
          {STYLES[infoName].displayName}
        </div>
        <div className="sp2-info-list-item-meter">
          <Meter
            background="#DADADA"
            size="full"
            thickness="small"
            max={meterMax}
            value={meterValue}
            color={STYLES[infoName].color}
          />
        </div>
        <div
          className="sp2-info-list-item-value"
          style={{ color: STYLES[infoName].color }}
        >
          {displayValue}
          <p className="sp2-info-list-item-value-unit">{STYLES[infoName].unit}</p>
        </div>
      </div>
    </li>
  );
}
