import { useGroup } from "../hooks/use-group";
import GlobalNavMenu from "./GlobalNavMenu";
import "./GlobalNav.css";

export default function GlobalNav({ onClickSubMenu }) {

  const { group } = useGroup();
  const homeSubMenu = group.houses
    .map(({ name, displayName }) => ({ text: displayName, path: `/${name}`}));
  const cameraImageSubMenu = group.houses
    .map(({ name, displayName }) => ({ text: displayName, path: `/camera-image/${name}`}));
  const spatialDistributionImageSubMenu = group.houses
    .map(({ name, displayName }) => ({ text: displayName, path: `/spatial-distribution-image/${name}`}));
  const detailSubMenu = group.houses
    .map(({ name, displayName }) => ({ text: displayName, path: `/detail/${name}`}));
  const imageAnalysisMenu = group.houses
    .map(({ name, displayName }) => ({ text: displayName, path: `/image-analysis/${name}`}));

  return (
    <nav className="globalnav">
      <ul>
        <li><GlobalNavMenu text={"ホーム"} subMenu={homeSubMenu} onClickSubMenu={onClickSubMenu} /></li>
        <li><GlobalNavMenu text={"カメラ画像"} subMenu={cameraImageSubMenu} onClickSubMenu={onClickSubMenu} /></li>
        <li><GlobalNavMenu text={"空間分布画像"} subMenu={spatialDistributionImageSubMenu} onClickSubMenu={onClickSubMenu} /></li>
        <li><GlobalNavMenu text={"画像解析"} subMenu={imageAnalysisMenu} onClickSubMenu={onClickSubMenu}/></li>
        <li><GlobalNavMenu text={"詳細表示"} subMenu={detailSubMenu} onClickSubMenu={onClickSubMenu} /></li>
      </ul>
    </nav>
  );
};
