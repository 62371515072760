import useLatestEnvInfo from "../../hooks/use-latest-env-info";
import "./CurrentValueEnvInfoListItem.css";
import icon_air_temperature from "../../assets/env_info/env_info_icon_air_temperature_1.png";
import icon_co2_concentration from "../../assets/env_info/env_info_icon_co2_concentration_1.png";
import icon_relative_humidity from "../../assets/env_info/env_info_icon_relative_humidity_1.png";
import icon_solar_irradiance from "../../assets/env_info/env_info_icon_solar_irradiance_1.png";

const ICONS = {
  air_temperature: icon_air_temperature,
  co2_concentration: icon_co2_concentration,
  relative_humidity: icon_relative_humidity,
  solar_irradiance: icon_solar_irradiance,
};

export default function CurrentValueEnvInfoListItem({ houseName, plantingDate, infoName, displayName, unit }) {

  let { value } = useLatestEnvInfo(infoName, houseName, plantingDate);

  if (infoName === "solar_irradiance" && value !== null) {
    // レイアウトが崩れるので小数点第一位までしか表示しない。
    value = Math.round(value * 10) / 10;
  }

  const displayValue = (value === null) ?  "N/A" : value;

  return (
    <div className="current-value-env-info-list-item-container">
      <div className="current-value-env-info-list-item-icon">
        <img src={ICONS[infoName]} alt={infoName} width="100%" />
      </div>
      <div className="current-value-env-info-list-item-content">
        <div className="current-value-env-info-list-item-value">
          {displayValue} <span className="current-value-env-info-list-item-value-unit">{unit}</span>
        </div>
      </div>
    </div>
  );
};
