import { useEffect, useState } from "react";
import { format, getHours } from "date-fns";
import imageAnalysisSpatialDistributionImageApi from "../../api/imageAnalysisSpatialDistributionImageApi";
import imageAnalysisFlowersAndFruitsImageApi from "../../api/imageAnalysisFlowersAndFruitsImageApi";
import Slider from "react-slick";
import ImageAnalysisPastImageSliderImage from "./ImageAnalysisPastImageSliderImage";
import ImageAnalysisPastImageSliderThumbnail from "./ImageAnalysisPastImageSliderThumbnail"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageAnalysisPastImageSlider.css";

const IMAGE_TYPE = {
  LIGHT: "light",
  PHOTO: "photo",
  TEMP: "temp",
  VERTICAL: "vertical",
  FLOWERS_AND_FRUITS: "flowersAndFruits"
};

export default function ImageAnalysisPastImageSlider({ houseName }) {

  const [selectedImageType, setSelectedImageType] = useState(IMAGE_TYPE.LIGHT);
  const [searchDate, setSearchDate] = useState(new Date());
  const [timestamps, setTimestamps] = useState([]);
  const [mainNav, setMainNav] = useState(null);
  const [thumbNav, setThumbNav] = useState(null);

  useEffect(() => {
    if (!houseName) return;
    if (selectedImageType === IMAGE_TYPE.FLOWERS_AND_FRUITS) {
      imageAnalysisFlowersAndFruitsImageApi
        .fetchTimestampsOfDay(houseName, searchDate)
        .then(timestamps => setTimestamps(timestamps));
    } else {
      imageAnalysisSpatialDistributionImageApi
        .fetchTimestampsOfDay(selectedImageType, houseName, searchDate)
        .then(timestamps => setTimestamps(timestamps));
    }
  }, [selectedImageType, houseName, searchDate]);;

  useEffect(() => {
    let index = 0;
    if (0 < timestamps.length) {
      const noonIndex = timestamps.findIndex(timestamp => getHours(timestamp) === 12);
      index = 0 <= noonIndex ? noonIndex : timestamps.length - 1;
    }
    if (mainNav && mainNav.innerSlider) {
      mainNav.slickGoTo(index, true);
    }
  }, [timestamps, mainNav])

  const handleSelectImageType = event => {
    setSelectedImageType(event.target.value);
  };

  const mainSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const thumbSliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  return (
    <div className="image-analysis-past-image-slider-container">
      <div className="image-analysis-past-image-slider-input-area">
        <div className="image-analysis-past-image-slider-input-item">
          <p>表示画像：</p>
          <select value={selectedImageType} onChange={handleSelectImageType}>
            <option value={IMAGE_TYPE.LIGHT}>受光量分布</option>
            <option value={IMAGE_TYPE.PHOTO}>光合成速度分布</option>
            <option value={IMAGE_TYPE.TEMP}>葉温</option>
            <option value={IMAGE_TYPE.VERTICAL}>鉛直分布</option>
            <option value={IMAGE_TYPE.FLOWERS_AND_FRUITS}>開花着果</option>
          </select>
        </div>
        <div className="image-analysis-past-image-slider-input-item">
          <p>取得日：</p>
          <input
            type="date"
            value={format(searchDate, "yyyy-MM-dd")}
            onChange={event => setSearchDate(new Date(event.target.value))}
          />
        </div>
      </div>
      <div className="image-analysis-past-image-slider-content">
        <div className="image-analysis-past-image-slider-slider-area">
          <div className="image-analysis-past-image-main-slider">
            {0 < timestamps.length &&
              <Slider
                asNavFor={thumbNav}
                ref={mainSlider => setMainNav(mainSlider)}
                {...mainSliderSettings}
              >
                {timestamps.map((timestamp, i) => (
                  <div key={i}>
                    <ImageAnalysisPastImageSliderImage
                      imageType={selectedImageType}
                      houseName={houseName}
                      timestamp={timestamp}
                    />
                    <div className="image-analysis-past-image-slider-slide-timestamp">{format(timestamp, "yyyy/MM/dd HH:mm:ss")}</div>
                  </div>
                ))}
              </Slider>
            }
          </div>
          <div className="image-analysis-past-image-thumb-slider">
            {0 < timestamps.length &&
              <Slider
                asNavFor={mainNav}
                ref={thumbSlider => setThumbNav(thumbSlider)}
                {...thumbSliderSettings}
              >
                {timestamps.map((timestamp, i) => (
                  <div key={i}>
                    <ImageAnalysisPastImageSliderThumbnail
                      imageType={selectedImageType}
                      houseName={houseName}
                      timestamp={timestamp}
                    />
                    <div className="image-analysis-past-image-slider-slide-timestamp">{format(timestamp, "HH:mm")}</div>
                  </div>
                ))}
              </Slider>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
