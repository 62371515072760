import { useState } from "react";
import ExifReader from "exifreader";
import { parse } from 'date-fns';

import imageAnalysisApi from "../../api/imageAnalysisApi";

import icon_lai from "../../assets/sp2_info/sp2_info_icon_lai_1.png";
import icon_flowers from "../../assets/sp2_info/sp2_info_icon_flowers_1.png";
import icon_fruits_bell_pepper from "../../assets/sp2_info/sp2_info_icon_fruits_bell_pepper_1.png";
import icon_fruits_chinese_chive from "../../assets/sp2_info/sp2_info_icon_fruits_chinese_chive_1.png";
import icon_fruits_corn from "../../assets/sp2_info/sp2_info_icon_fruits_corn_1.png";
import icon_fruits_cucumber from "../../assets/sp2_info/sp2_info_icon_fruits_cucumber_1.png";
import icon_fruits_eggplant from "../../assets/sp2_info/sp2_info_icon_fruits_eggplant_1.png";
import icon_fruits_grape from "../../assets/sp2_info/sp2_info_icon_fruits_grape_1.png";
import icon_fruits_green_pepper from "../../assets/sp2_info/sp2_info_icon_fruits_green_pepper_1.png";
import icon_fruits_green_pepper_s from "../../assets/sp2_info/sp2_info_icon_fruits_green_pepper_s_1.png";
import icon_fruits_mandarin_orange from "../../assets/sp2_info/sp2_info_icon_fruits_mandarin_orange_1.png";
import icon_fruits_melon from "../../assets/sp2_info/sp2_info_icon_fruits_melon_1.png";
import icon_fruits_spinach from "../../assets/sp2_info/sp2_info_icon_fruits_spinach_1.png";
import icon_fruits_strawberry from "../../assets/sp2_info/sp2_info_icon_fruits_strawberry_1.png";
import icon_fruits_tomato from "../../assets/sp2_info/sp2_info_icon_fruits_tomato_1.png";
import icon_fruits_wasabi from "../../assets/sp2_info/sp2_info_icon_fruits_wasabi_1.png";

import "./ImageAnalysisCurrent.css";

const FRUITS_ICONS = {
  "bell-pepper": icon_fruits_bell_pepper,
  "chinese-chive": icon_fruits_chinese_chive,
  "corn": icon_fruits_corn,
  "cucumber": icon_fruits_cucumber,
  "eggplant": icon_fruits_eggplant,
  "grape": icon_fruits_grape,
  "green-pepper": icon_fruits_green_pepper,
  "green-pepper-s": icon_fruits_green_pepper_s,
  "mandarin-orange": icon_fruits_mandarin_orange,
  "melon": icon_fruits_melon,
  "spinach": icon_fruits_spinach,
  "strawberry": icon_fruits_strawberry,
  "tomato": icon_fruits_tomato,
  "wasabi": icon_fruits_wasabi,
};

const displayValue = (value, digits) => (value === null) ? "N/A" : Number.parseFloat(value).toFixed(digits);

export default function ImageAnalysisCurrent({ houseName, cropName }) {

  const [timestamp, setTimestamp] = useState(null);
  const [dataURL, setDataURL] = useState(null);
  const [savedDataURL, setSavedDataURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageAnalysisData, setImageAnalysisData] = useState(null);

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const tags = await ExifReader.load(file);
      if (tags.DateTimeOriginal && tags.DateTimeOriginal.description) {
        const parsedDate = parse(tags.DateTimeOriginal.description, 'yyyy:MM:dd HH:mm:ss', new Date());
        setTimestamp(parsedDate.toISOString())
      } else {
        const lastModifiedDate = new Date(file.lastModified);
        setTimestamp(lastModifiedDate.toISOString())
      }
      const reader = new FileReader();
      reader.onload = () => {
        setDataURL(reader.result);
        setSavedDataURL(null);
        setImageAnalysisData(null);
      };
      reader.readAsDataURL(file);
    } else {
      setDataURL(null);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {

      const response = await imageAnalysisApi.fetch(houseName, cropName, timestamp, dataURL.split(',')[1]);
      if (response) {
        setSavedDataURL(dataURL);
        setImageAnalysisData(response);
        setDataURL(null);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="image-analysis-current-container">
      {!loading && (
        <div className="image-analysis-current-update-area">
          <div className="image-analysis-current-update-button">
            <label htmlFor="picture" >画像を撮影</label>
            <input
              style={{ display: "none" }}
              type="file"
              id="picture"
              accept="image/jpeg"
              capture="environment"
              onChange={handleChangeImage}
            />
          </div>
          {dataURL && (
            <img className="image-analysis-current-update-image" src={dataURL} alt="撮影画像" />
          )}
          {dataURL && (
            <button className="image-analysis-current-analysis-button" onClick={handleSubmit}>解析</button>
          )}
        </div>
      )}
      {loading && (
        <div className="image-analysis-current-loading-area">
          <div className="image-analysis-current-loading-spinner"></div>
        </div>
      )}
      {imageAnalysisData !== null && (
        <div className="image-analysis-current-result-area">
          <div className="image-analysis-current-result-title">
            <div className="image-analysis-current-result-title-text">解析結果</div>
          </div>
          <div className="image-analysis-current-sp2-info-list-area">
            {imageAnalysisData.lai && (
              <ul>
                <li className="image-analysis-current-sp2-info-list-item-container">
                  <div className="image-analysis-current-sp2-info-list-item-icon">
                    <img src={icon_lai} alt="LAI" width="100%" />
                  </div>
                  <div className="image-analysis-current-sp2-info-list-item-content">
                    <div
                      className="image-analysis-current-sp2-info-list-item-value"
                      style={{ color: "#39B54A" }}
                    >
                      {displayValue(imageAnalysisData.lai, 1)} <span className="image-analysis-current-sp2-info-list-item-value-unit"> m2/m2</span>
                    </div>
                  </div>
                </li>
                <li className="image-analysis-current-sp2-info-list-item-container">
                  <div className="image-analysis-current-sp2-info-list-item-icon">
                    <img src={icon_flowers} alt="開花数" width="100%" />
                  </div>
                  <div className="image-analysis-current-sp2-info-list-item-content">
                    <div
                      className="image-analysis-current-sp2-info-list-item-value"
                      style={{ color: "#FF7BAC" }}
                    >
                      {displayValue(imageAnalysisData.flowers, 0)} <span className="image-analysis-current-sp2-info-list-item-value-unit"> 個</span>
                    </div>
                  </div>
                </li>
                <li className="image-analysis-current-sp2-info-list-item-container">
                  <div className="image-analysis-current-sp2-info-list-item-icon">
                    <img src={FRUITS_ICONS[cropName]} alt="着果数" width="100%" />
                  </div>
                  <div className="image-analysis-current-sp2-info-list-item-content">
                    <div className="image-analysis-current-sp2-info-list-item-value">
                      <div style={{ color: "#008000" }}>
                        {displayValue(imageAnalysisData.unripeFruits, 0)} <span className="image-analysis-current-sp2-info-list-item-value-unit"> 個(未熟)</span>
                      </div>
                      <div style={{ color: "#ED1A3D" }}>
                        {displayValue(imageAnalysisData.ripeFruits, 0)} <span className="image-analysis-current-sp2-info-list-item-value-unit"> 個(成熟)</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className="image-analysis-current-sp2-images-area">
            <div className="image-analysis-current-sp2-image-area">
              <div>撮影画像</div>
              <div>
                <img
                  src={savedDataURL}
                  alt="撮影画像" width="100%"
                />
              </div>
            </div>
            {imageAnalysisData.flowersAndFruitsImage && (
              <div className="image-analysis-current-sp2-image-area">
                <div>開花着果</div>
                <div>
                  <img
                    src={`data:image/jpeg;base64,${imageAnalysisData.flowersAndFruitsImage}`}
                    alt="開花着果" width="100%"
                  />
                </div>
              </div>
            )}
            {imageAnalysisData.spatialDistributionImages && (
              <>
                <div className="image-analysis-current-sp2-image-area">
                  <div>受光量分布</div>
                  <div>
                    <img
                      src={`data:image/jpeg;base64,${imageAnalysisData.spatialDistributionImages.light[0].value}`}
                      alt="受光量分布" width="100%"
                    />
                  </div>
                </div>
                <div className="image-analysis-current-sp2-image-area">
                  <div>光合成速度分布</div>
                  <div>
                    <img
                      src={`data:image/jpeg;base64,${imageAnalysisData.spatialDistributionImages.photo[0].value}`}
                      alt="光合成速度分布" width="100%"
                    />
                  </div>
                </div>
                <div className="image-analysis-current-sp2-image-area">
                  <div>葉温</div>
                  <div>
                    <img
                      src={`data:image/jpeg;base64,${imageAnalysisData.spatialDistributionImages.temp[0].value}`}
                      alt="葉温" width="100%"
                    />
                  </div>
                </div>
                <div className="image-analysis-current-sp2-image-area">
                  <div>鉛直分布</div>
                  <div>
                    <img
                      src={`data:image/jpeg;base64,${imageAnalysisData.spatialDistributionImages.vertical[0].value}`}
                      alt="鉛直分布" width="100%"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
