import { useEffect, useState } from "react";
import imageAnalysisSpatialDistributionThumbnailApi from "../../api/imageAnalysisSpatialDistributionThumbnailApi";
import imageAnalysisFlowersAndFruitsThumbnailApi from "../../api/imageAnalysisFlowersAndFruitsThumbnailApi";

export default function ImageAnalysisPastImageSliderThumbnail({ imageType, houseName, timestamp }) {

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!imageType || !houseName || !timestamp) return;
    if (imageType === "flowersAndFruits") {
      imageAnalysisFlowersAndFruitsThumbnailApi.fetch(
        houseName,
        timestamp.toISOString(),
        timestamp.toISOString(),
      ).then(response => setImage(response[0]));

    } else {
      imageAnalysisSpatialDistributionThumbnailApi.fetch(
        imageType,
        houseName,
        timestamp.toISOString(),
        timestamp.toISOString(),
      ).then(response => setImage(response[0]));
    }
    return () => setImage(null)
  }, [imageType, houseName, timestamp]);;

  return (
    <div>
      {image && <img src={`data:image/${image.fileType};base64,${image.value}`} width="100%" alt={image.timestamp} />}
    </div>
  );
}
