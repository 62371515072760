import { useState, useEffect } from "react";
import { subDays, startOfDay, endOfDay, format } from "date-fns";
import Plot from "react-plotly.js";
import client from "../../api/client";
import { useCanopy as _useCanopy } from "../../utils/displaySettingUtil";
import { displayValue } from "../../utils/displayUtil";

const sortHourly = data => {
  return data.reduce((result, { timestamp, value }) => {
    const hour = format(new Date(timestamp), "HH");
    if (!result[hour]) result[hour] = [];
    result[hour].push(value);
    return result;
  }, {});
};

const calcHourlyMean = data => {
  const result = Object.entries(data).reduce((result, [key, value]) => {
    result[0].push(key);
    result[1].push(value.reduce((sum, num) => sum + num, 0) / value.length);
    return result;
  }, [[], []]);
  return result;
};

const sortByHour = data => {
  const paired = data[0].map((value, index) => [value, data[1][index]]);
  paired.sort((a, b) => a[0].localeCompare(b[0]));
  const result = [
    paired.map(pair => pair[0]),
    paired.map(pair => pair[1])
  ];
  return result;
};

const getPlotData = async (houseName, latestTimestamp, displaySetting) => {
  const startDate = startOfDay(subDays(latestTimestamp, 7));
  const endDate = endOfDay(subDays(latestTimestamp, 1));
  const baseParams = {
    house_name: houseName,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    select: "mean",
    sampling_step: "H",
    sampling_interval: 1,
  };

  let envResponseData = {
    "solar_irradiance": [],
    "co2_concentration": []
  };
  try {
    const envResponse = await client.get("/env", {
      params: {
        ...baseParams,
        targets: [
          "solar_irradiance",
          "co2_concentration",
        ]
      }
    });
    if (envResponse.status === 200) {
      envResponseData = { ...envResponse.data };
    }
  } catch (error) {
    console.log(error);
  }

  const photoUrl =
    _useCanopy(displaySetting)
      ? "/sp2/db/canopy_photosynthetic_rate"
      : "/sp2/db/photosynthetic_rate";
  let sp2ResponseData = [];
  try {
    const sp2Response = await client.get(photoUrl, {
      params: baseParams
    });
    if (sp2Response.status === 200) {
      sp2ResponseData = sp2Response.data;
    }
  } catch (error) {
    console.log(error);
  }

  const solarIrradiance = sortByHour(calcHourlyMean(sortHourly(envResponseData["solar_irradiance"])));
  const co2Concentration = sortByHour(calcHourlyMean(sortHourly(envResponseData["co2_concentration"])));
  const photosyntheticRate = sortByHour(calcHourlyMean(sortHourly(sp2ResponseData)));

  return { solarIrradiance, co2Concentration, photosyntheticRate };
};

export default function DailyReport7DayMeanPlot({ houseName, latestTimestamp, displaySetting }) {

  const [plotData, setPlotData] = useState([]);

  useEffect(() => {

    if (!latestTimestamp || !latestTimestamp instanceof Date) return;
    if (!displaySetting) return;

    getPlotData(houseName, latestTimestamp, displaySetting)
      .then(data => {
        const solarIrradiancePlotData = {
          type: "bar",
          name: "日射量",
          x: data.solarIrradiance[0],
          y: data.solarIrradiance[1].map(value => displayValue(value)),
          yaxis: "y",
          hovertemplate: "%{y} W/m2"
        };
        const co2ConcentrationPlotData = {
          type: "scatter",
          mode: "lines+markers",
          name: "CO2濃度",
          x: data.co2Concentration[0],
          y: data.co2Concentration[1].map(value => displayValue(value)),
          yaxis: "y2",
          hovertemplate: "%{y} ppm"
        };
        const photosyntheticRatePlotData = {
          type: "scatter",
          mode: "lines+markers",
          name: "光合成速度",
          x: data.photosyntheticRate[0],
          y: data.photosyntheticRate[1].map(value => displayValue(value)),
          yaxis: "y3",
          hovertemplate: "%{y} µmol/m2/s"
        };

        setPlotData([
          solarIrradiancePlotData,
          co2ConcentrationPlotData,
          photosyntheticRatePlotData,
        ]);
      });

  }, [houseName, latestTimestamp, displaySetting]);

  const plotLayout = {
    autoresize: true,
    margin: {
      r: 50,
    },
    title: {
      text: "過去7日間平均",
      automargin: true,
      pad: {
        t: 20
      }
    },
    legend: {
      orientation: "h",
      x: 0,
      y: 1.0,
      xanchor: 'left',
      yanchor: 'bottom'
    },
    hovermode: "x unified",
    dragmode: false,
    yaxis: {
      title: "日射量 (W/m2)",
      range: [0, 1000],
      fixedrange: true,
    },
    yaxis2: {
      overlaying: 'y',
      title: "CO2濃度 (ppm)",
      range: [0, 1000],
      fixedrange: true,
      side: "left",
      anchor: "free",
      autoshift: false,
      shift: -30,
      showticklabels: false,
    },
    yaxis3: {
      overlaying: 'y',
      title: "光合成速度 (µmol/m2/s)",
      range: [-5, 25],
      fixedrange: true,
      side: "right",
      showgrid: false,
      zeroline: false
    },
  };

  const plotConfig = {
    displayModeBar: false,
  };

  return (
    <Plot
      data={plotData}
      layout={plotLayout}
      config={plotConfig}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
    />
  );
};
