import useLatestSP2Info from "../../hooks/use-latest-sp2-info";
import { useCanopy as _useCanopy } from "../../utils/displaySettingUtil";

import "./CurrentValueSP2InfoListItem.css";
import icon_lai from "../../assets/sp2_info/sp2_info_icon_lai_1.png";
import icon_leaf_temperature from "../../assets/sp2_info/sp2_info_icon_leaf_temperature_1.png";
import icon_photosynthetic_rate from "../../assets/sp2_info/sp2_info_icon_photosynthetic_rate_1.png";
import icon_transpiration_rate from "../../assets/sp2_info/sp2_info_icon_transpiration_rate_1.png";

const STYLES = {
  lai: {
    displayName: "LAI",
    unit: "m2/m2" ,
    icon: icon_lai,
    color: "#39B54A",
    digits: 1,
  },
  leaf_temperature: {
    displayName: "葉温",
    unit: "℃",
    icon: icon_leaf_temperature,
    color: "#D4686C",
    digits: 1,
  },
  photosynthetic_rate: {
    displayName: "光合成速度",
    unit: "µmol/m2/s",
    icon: icon_photosynthetic_rate,
    color: "#FF1D25",
    digits: 1,
  },
  transpiration_rate: {
    displayName: "蒸散速度",
    unit: "mmol/m2/s",
    icon: icon_transpiration_rate,
    color: "#3FA9F5",
    digits: 1,
  },
};

const isCanopy = (displaySetting, infoName) => {
  if (infoName === "photosynthetic_rate" ||  infoName === "transpiration_rate") {
    return _useCanopy(displaySetting);
  }
  return false;
};

export default function CurrentValueSP2InfoListItem({ infoName, groupName, houseName, cameraName, cropName, plantingDate, displaySetting }) {

  const { value } = useLatestSP2Info(infoName, groupName, houseName, cameraName, cropName, plantingDate, isCanopy(displaySetting, infoName));

  const displayValue = (value === null) ? "N/A" : Number.parseFloat(value).toFixed(STYLES[infoName].digits);

  return (
    <li className="current-value-sp2-info-list-item-container">
      <div className="current-value-sp2-info-list-item-icon">
        <img src={STYLES[infoName].icon} alt={STYLES[infoName].displayName} width="100%" />
      </div>
      <div className="current-value-sp2-info-list-item-content">
        <div
          className="current-value-sp2-info-list-item-value"
          style={{ color: STYLES[infoName].color }}
        >
          {displayValue} <span className="current-value-sp2-info-list-item-value-unit">{STYLES[infoName].unit}</span>
        </div>
      </div>
    </li>
  );
}
