import { startOfDay, endOfDay } from "date-fns";
import client from "./client";

const fetch = async (imageType, houseName, start = undefined, end = undefined, select = undefined, noValue = false) => {
  const url = `/image_analysis/sp2/db/spatial_distribution_thumbnails/${imageType}`
  try {
    const response = await client.get(url, {
      params: {
        house_name: houseName,
        start,
        end,
        select,
        no_value: noValue
      }
    });
    return response.data.map(({ house_name, timestamp, file_type, value }) => ({
      houseName: house_name,
      timestamp,
      fileType: file_type,
      value
    }));
  } catch (error) {
    throw error;
  }
};

const fetchLatestInDay = async (imageType, houseName, date) => {
  try {
    const start = startOfDay(date).toISOString();
    const end = endOfDay(date).toISOString();
    const response = await fetch(imageType, houseName, start, end, "latest");
    if (!response || !response.length) return;
    return response[0];
  } catch (error) {
    throw error;
  }
}

const imageAnalysisSpatialDistributionThumbnailApi = {
  fetch,
  fetchLatestInDay,
};

export default imageAnalysisSpatialDistributionThumbnailApi;
