import { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { format, addHours, subHours, subDays, startOfDay, endOfDay } from "date-fns";
import { format as formatTZ } from "date-fns-tz";
import client from "../../api/client";

import "./ImageAnalysisPastPlot.css";

const SP2_INFO_NAMES = ["lai", "flowers", "unripe_fruits", "ripe_fruits"];
const SP2_INFO_PLOT_CONFIG = {
  lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
  flowers: { displayName: "開花数", hovertemplate: "%{y} 個", color: "#FF7BAC" },
  unripe_fruits: { displayName: "着果数 (未熟)", hovertemplate: "%{y} 個", color: "#008000" },
  ripe_fruits: { displayName: "着果数 (完熟)", hovertemplate: "%{y} 個", color: "#ED1A3D" },
};

async function getPlotData(infoNames, houseName, startDate, endDate) {

  const srcData = {};

  const params = {
    house_name: houseName,
    start: startOfDay(new Date(startDate)).toISOString(),
    end: endOfDay(new Date(endDate)).toISOString(),
  };

  for (const infoName of infoNames) {

    const url = `/image_analysis/sp2/db/${infoName}`;

    try {
      const response = await client.get(url, { params });

      const convertedData = response.data.reduce((resultArray, { timestamp, value }) => {
        resultArray[0].push(formatTZ(new Date(timestamp), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
        resultArray[1].push(value);
        return resultArray;
      }, [[], []]);

      srcData[infoName] = convertedData;
    } catch (error) {
      console.log(error);
    }
  }

  return Object.keys(srcData).map((key, i) => ({
    type: "scatter",
    mode: "lines+markers",
    name: SP2_INFO_PLOT_CONFIG[key].displayName,
    x: srcData[key][0],
    y: srcData[key][1],
    yaxis: 0 < i ? `y${i + 1}` : "y",
    line: { color: SP2_INFO_PLOT_CONFIG[key].color },
    hovertemplate: SP2_INFO_PLOT_CONFIG[key].hovertemplate,
  }));
};

export default function ImageAnalysisPastPlot({ houseName }) {

  const now = new Date();

  const [startDate, setStartDate] = useState(format(subDays(now, 7), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(now, "yyyy-MM-dd"));
  const [plotData, setPlotData] = useState([]);
  const [plotXaxisRange, setPlotXaxisRange] = useState([
    format(subHours(now, 3), "yyyy-MM-dd HH:mm:ss"),
    format(addHours(now, 3), "yyyy-MM-dd HH:mm:ss")
  ]);
  const [plotSliderRange, setPlotSliderRange] = useState([
    format(startOfDay(new Date(startDate)), "yyyy-MM-dd HH:mm:ss"),
    format(endOfDay(new Date(endDate)), "yyyy-MM-dd HH:mm:ss")
  ]);

  useEffect(() => {

    const _now = new Date();
    const _startDate = format(subDays(_now, 7), "yyyy-MM-dd");
    const _endDate = format(_now, "yyyy-MM-dd");
    getPlotData(SP2_INFO_NAMES, houseName, _startDate, _endDate)
      .then(data => setPlotData(data))
      .catch(error => console.log(error));
    return () => {
      setStartDate(_startDate);
      setEndDate(_endDate);
      setPlotData([]);
      setPlotXaxisRange([
        format(subHours(_now, 3), "yyyy-MM-dd HH:mm:ss"),
        format(addHours(_now, 3), "yyyy-MM-dd HH:mm:ss")
      ]);
      setPlotSliderRange([
        format(startOfDay(new Date(_startDate)), "yyyy-MM-dd HH:mm:ss"),
        format(endOfDay(new Date(_endDate)), "yyyy-MM-dd HH:mm:ss")
      ]);
    };
  }, [houseName]);

  const handleGetDataButtonClick = () => {
    getPlotData(SP2_INFO_NAMES, houseName, startDate, endDate)
      .then(data => {
        setPlotData(data);
        setPlotXaxisRange([
          format(startOfDay(new Date(startDate)), "yyyy-MM-dd HH:mm:ss"),
          format(endOfDay(new Date(endDate)), "yyyy-MM-dd HH:mm:ss")
        ]);
        setPlotSliderRange([
          format(startOfDay(new Date(startDate)), "yyyy-MM-dd HH:mm:ss"),
          format(endOfDay(new Date(endDate)), "yyyy-MM-dd HH:mm:ss")
        ]);
      })
      .catch(error => console.log(error));
  };

  const handlePlotRelayout = event => {
    if (event["xaxis.range"]) {
      setPlotXaxisRange(event["xaxis.range"]);
    } else if (event["xaxis.range[0]"] && event["xaxis.range[1]"]) {
      setPlotXaxisRange([event["xaxis.range[0]"], event["xaxis.range[1]"]]);
    } else {
      setPlotXaxisRange([]);
    }
  };

  const layout = {
    autosize: true,
    height: 600,
    margin: {
      l: 30,
      r: 30,
    },
    hovermode: "x unified",
    xaxis: {
      type: "date",
      range: plotXaxisRange,
      rangeslider: {
        visible: true,
        range: plotSliderRange
      },
      rangeselector: {
        buttons: [
          {
            step: 'hour', //| 'month' | 'year' | 'all';
            stepmode: 'backward',
            count: 6,
            label: "6h"
          },
          {
            step: 'day', //| 'month' | 'year' | 'all';
            stepmode: 'backward',
            count: 1,
            label: "1日"
          },
          {
            step: 'day', //| 'month' | 'year' | 'all';
            stepmode: 'backward',
            count: 7,
            label: "7日"
          },
          {
            step: "all",
            stepmode: "backward",
            label: "ALL"
          }
        ]
      }
    },
    yaxis: {
      visible: false,
    },
    yaxis2: {
      overlaying: 'y',
      visible: false,
    },
    yaxis3: {
      overlaying: 'y',
      visible: false,
    },
    yaxis4: {
      overlaying: 'y',
      visible: false,
    },
  };

  const config = {
    displayModeBar: false,
    responsive: true
  };

  return (
    <div className="image-analysis-past-plot-container">
      <div className="image-analysis-past-plot-input-area">
        <div className="image-analysis-past-plot-input-item">
          <p>取得範囲：</p>
          <div>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={event => setStartDate(event.target.value)}
            />
            <span> 〜 </span>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={event => setEndDate(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="image-analysis-past-plot-button-area">
        <button onClick={handleGetDataButtonClick}>データ取得</button>
      </div>
      <div className="image-analysis-past-plot-plot-area">
        <Plot
          data={plotData}
          style={{ width: "100%" }}
          layout={layout}
          config={config}
          onRelayout={handlePlotRelayout}
        />
      </div>
    </div>
  );
}
