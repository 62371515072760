import { useState } from "react";
import { useParams } from "react-router-dom";

import { useGroup } from "../../hooks/use-group";
import House from "../shared/House";
import ImageAnalysisCurrent from "./ImageAnalysisCurrent";
import ImageAnalysisPast from "./ImageAnalysisPast";

import "./ImageAnalysis.css";

const TAB_TYPE_CURRENT = 0
const TAB_TYPE_PAST = 1

export default function ImageAnalysis() {

  const [activeTab, setActiveTab] = useState(TAB_TYPE_CURRENT);

  const { group } = useGroup();
  const { houseName } = useParams();
  const house = group.houses.find(house => house.name === houseName);
  const crop = house.cultivation.crop;

  return (
    <>
      <House
        displayName={house.displayName}
        cropName={crop.name}
        cropDisplayName={crop.displayName}
        plantingDate={house.cultivation.plantingDate}
      >
        <div className="image-analysis-container">
          <div className="image-analysis-tabs">
            <button
              className={`image-analysis-tab ${activeTab === TAB_TYPE_CURRENT ? "image-analysis-active-tab" : ""}`}
              onClick={() => setActiveTab(TAB_TYPE_CURRENT)}
            >
              画像解析
            </button>
            <button
              className={`image-analysis-tab ${activeTab === TAB_TYPE_PAST ? "image-analysis-active-tab" : ""}`}
              onClick={() => setActiveTab(TAB_TYPE_PAST)}
            >
              過去データ
            </button>
          </div>
          <div className="image-analysis-content">
            {activeTab === TAB_TYPE_CURRENT && <ImageAnalysisCurrent houseName={house.name} cropName={crop.name} />}
            {activeTab === TAB_TYPE_PAST && <ImageAnalysisPast houseName={house.name} />}
          </div>
        </div>
      </House>
    </>
  );
};
