import client from "./client";

const fetch = async (houseName, cropName, timestamp, image) => {
  try {
    const response = await client.post("/image_analysis", {
      house_name: houseName,
      crop_name: cropName,
      timestamp,
      image
    });
    if (!response.data) return null;
    return { 
      lai: response.data.lai, 
      flowers: response.data.flowers, 
      unripeFruits: response.data.unripe_fruits, 
      ripeFruits: response.data.ripe_fruits, 
      flowersAndFruitsImage: response.data.flowers_and_fruits_image,
      spatialDistributionImages: response.data.spatial_distribution_images 
    };
  } catch (error) {
    throw error;
  }
}

const imageAnalysisApi = {
  fetch
};

export default imageAnalysisApi;
