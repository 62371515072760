import { useEffect, useState } from "react";
import { setMilliseconds } from "date-fns";
import imageAnalysisImageApi from "../../api/imageAnalysisImageApi";
import imageAnalysisSpatialDistributionImageApi from "../../api/imageAnalysisSpatialDistributionImageApi";
import imageAnalysisFlowersAndFruitsImageApi from "../../api/imageAnalysisFlowersAndFruitsImageApi";

import "./ImageAnalysisPastImageSliderImage.css";

export default function ImageAnalysisPastImageSliderImage({ imageType, houseName, timestamp }) {

  const [cameraImage, setCameraImage] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!imageType || !houseName || !timestamp) return;

    imageAnalysisImageApi.fetch(
      houseName,
      timestamp.toISOString(),
      // AIエンジンが分布画像のタイムスタンプとしてカメラ画像のタイムスタンプからミリ秒を削除した値を使用しているので
      // 分布画像とカメラ画像のタイムスタンプの差異を吸収するため
      setMilliseconds(timestamp, 999).toISOString(),
    ).then(response => setCameraImage(response[0]));

    if (imageType === "flowersAndFruits") {
      imageAnalysisFlowersAndFruitsImageApi.fetch(
        houseName,
        timestamp.toISOString(),
        timestamp.toISOString()
      ).then(response => setImage(response[0]))
    } else {
      imageAnalysisSpatialDistributionImageApi.fetch(
        imageType,
        houseName,
        timestamp.toISOString(),
        timestamp.toISOString()
      ).then(response => setImage(response[0]));
    }

    return () => {
      setCameraImage(null);
      setImage(null);
    };
  }, [imageType, houseName, timestamp]);;

  return (
    <div className="image-analysis-past-image-slider-image-container">
      <div>
        {cameraImage && (
          <img
            src={`data:image/${cameraImage.fileType};base64,${cameraImage.value}`}
            width="100%"
            alt={cameraImage.timestamp} />
        )}
      </div>
      <div>
        {image && (
          <img
            src={`data:image/${image.fileType};base64,${image.value}`}
            width="100%"
            alt={image.timestamp} />
        )}
      </div>
    </div>
  );
}
